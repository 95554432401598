import { defineStore } from 'pinia';
import http from './http';
import { Response } from '@/types';
import { useMenuStore } from './menu';
import { useAuthStore } from '@/plugins';
import { message } from 'ant-design-vue';

export interface Profile {
  account: Account;
  permissions: string[];
  role: string;
}
export interface Account {
  username: string;
  avatar: string;
  gender: number;
}

export type TokenResult = {
  error: string | null;
  detail: string | null;
  token: string;
};
export const useAccountStore = defineStore('account', {
  state() {
    return {
      account: {} as Account,
      permissions: [] as string[],
      role: '',
      logged: true,
    };
  },
  actions: {
    async login(token: string) {
      return http
        .request<TokenResult, Response<TokenResult>>('/1/me', 'get', null, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
        .then(async (response) => {
          if (response.code === 200) {
            this.logged = true;
            const expiresIn = 3 * 24 * 60 * 60 * 1000;
            http.setAuthorization(`Bearer ${token}`, expiresIn + new Date().getTime());
            await useMenuStore().getMenuList();
            return response.data;
          } else if (response.code == 401) {
            message.error(response.data.detail)
          } else {
            message.error(response.data.error)
          }
        });
    },
    async logout() {
      return new Promise<boolean>((resolve) => {
        localStorage.removeItem('stepin-menu');
        http.removeAuthorization();
        this.logged = false;
        resolve(true);
      });
    },
    setLogged(logged: boolean) {
      this.logged = logged;
    },
  },
});
